import {
  Component,
  OnInit,
  OnDestroy
} from "@angular/core";

import {
  Validators,
  FormGroup,
  FormControl
} from "@angular/forms";

import {
  RelayService
} from "../services/relay-service";

import {Socket} from 'ng-socket-io';

import 'rxjs/add/operator/switchMap';
import {ActivatedRoute, NavigationEnd, Router} from '@angular/router';
import {distinctUntilChanged, filter, map} from 'rxjs/operators';

import {NotifyService} from "../../lib/services/notify.service";
import {makeActionDialog} from './makeActionDialog.component';
import {MatDialog} from "@angular/material";
import {getToken} from '../../utils/authTokens';
import {UserService} from "../../user/services/user-service";

import {ChangeRelayNameDialog} from '../../user/components/changeRelayNameDialog.component';
import {RemoveRelayDialog} from '../../user/components/removeRelayDialog.component';
import { getSyncCounterData, calculateSyncCounterOffset, SyncCounterType } from '../services/helper'

import {
  KMA_LIKE_RELAY_TYPES,
  TOP_326_T01,
  TOP_323_T01,
  TOP_323_T5,
  TOP_324_T5,
  TOP_324_T01,
  TOP_RELAYS,
  TEMPERATURE_RELAY_TYPES,
  KCP_323_60,
  TOP_323_HT5,
  TOP_326_T5,
  KCP_301_60,
  TOC_326_T5,
  TOP_326_HT5,
  KCP_303_60,
  TOT_104_TH3,
  KMT_101_40,
  TOT_106_TH6,
  TEP_321_T01,
  RELAYS_3_NAMED,
  RELAYS_4_NAMED,
  RELAYS_6_NAMED
} from '../constants';

const OFFLINE_WIFI_TOOLTIP = "Рівень сигналу приладу: offline";

@Component({
  selector: "relay-cmp",
  templateUrl: "../templates/relay.html",
  styleUrls: ["../styles/relay.scss"]
})
export class RelayCmp implements OnInit, OnDestroy {
  openedMenu: boolean = false;
  isMobile: boolean = false;
  loading: boolean = true;
  isRelayConnected: boolean = false;
  lastRelayConnection: number = 0;
  relay: any = {
    status: false,
    energySettings: {}
  };
  user: any = {};
  costs: any = {};
  readings: any = [];
  voltage: any = {
    range: [],
    line: [],
    postfix: ', V',
    type: 'voltage'

  };
  current: any = {
    range: [],
    line: [],
    postfix: ', A',
    type: 'current'
  };
  relayId: string;
  relaySN: string;
  relayState: boolean = false;
  disableAction = true;
  sendAction = false;
  openedActionModal = false;
  intervalForData: any;
  lastAction: boolean = false;

  subscriptions: any = [];
  intervals: any = [];
  relays: any;
  wifi_level: number = 0;
  wifi_tooltip: string = OFFLINE_WIFI_TOOLTIP;
  relayName: string = "";
  mode_auto: boolean = false;
  KCP_323_60: string = KCP_323_60;
  TOP_326_T01: string = TOP_326_T01;
  TOP_326_T5: string = TOP_326_T5;
  TOP_323_T01: string = TOP_323_T01;
  TOP_323_T5: string = TOP_323_T5;
  TOP_324_T01: string = TOP_324_T01;
  TOP_324_T5: string = TOP_324_T5;
  TOP_323_HT5: string = TOP_323_HT5;
  TOP_326_HT5: string = TOP_326_HT5;
  KCP_301_60: string = KCP_301_60;
  KCP_303_60: string = KCP_303_60;
  TOT_104_TH3: string = TOT_104_TH3;
  TOT_106_TH6: string = TOT_106_TH6;
  KMT_101_40: string = KMT_101_40;
  SWITCHER_3_TYPES: string[] = [TOP_323_T01, TOP_323_T5, KCP_323_60, TOP_323_HT5, KCP_303_60];
  TOP_RELAYS: string[] = TOP_RELAYS;
  TEP_321_T01: string = TEP_321_T01;
  TEMPERATURE_RELAY_TYPES: string[] = TEMPERATURE_RELAY_TYPES;
  KMA_LIKE_RELAY_TYPES: string[] = KMA_LIKE_RELAY_TYPES;
  TOC_326_T5: string = TOC_326_T5;
  torRelaysState: number[] = [0, 0, 0, 0, 0, 0];
  torRelayNames: string[] = ['реле 1', 'реле 2', 'реле 3', 'реле 4', 'реле 5', 'реле 6'];
  torRelaysStateLoading: boolean = false
  torActionTimeout: any;
  energyActive: number = 0;
  energyActiveFloor: number = 0;
  syncCounter: SyncCounterType = {
    show: false,
    value: 0,
    offset: 0,
  };
  showApplianceButton: boolean = true;
  ssidName: string = '';

  constructor(
    private _relayService: RelayService,
    private route: ActivatedRoute,
    private router: Router,
    private socket: Socket,
    private notifyService: NotifyService,
    private _dialog: MatDialog,
    private _userService: UserService
  ) {
    this.relaySN = this.route.snapshot.paramMap.get('sn');
    this.isMobile = window.innerWidth < 1000
    this.socket.on('reconnect', () => {
        console.log('reconnect');
        this.socket.emit('join room', {relayId: this.relayId, token: getToken()});
        this.socket.emit('join room monitoring', {relayId: this.relayId, token: getToken()});
      }
    );
  }

  ngOnInit() {
    this.openedMenu = window.innerWidth > 999;

    this._relayService.getRelayValue()
      .subscribe((relay: any) => {
        if (!relay || !Object.keys(relay).length) {
          return;
        }

        // do not show Appliance button to relay KIA, KMA, KCP, TOP, KMT_101_40
          this.showApplianceButton = !(relay.typeRelay || '').match(/^KIA|KMA|KCP|TOP|TOT_104_TH3|KMT_101_40|TOC_326_T5|TOT_106_TH6|TEP_321_T01/i);
        // end

        this.setRelay(relay);
      });

    this.route.paramMap
      .subscribe((params) => {
        this.relaySN = params.get('sn')
        if (!this.relaySN) {
          let snArr = window.location.href.match(/relay\/(\w*)/);

          this.relaySN = snArr ? snArr[1] : '';
        }

        this.relayId && this.socket.emit('leave room', {relayId: this.relayId, token: getToken()});

        this.subscriptions.forEach((item) => {
          item.unsubscribe();
        })

        this.intervals.forEach((item) => {
          clearInterval(item);
        })

        this.mode_auto = false;
        this.isRelayConnected = false;
        this.wifi_tooltip = OFFLINE_WIFI_TOOLTIP;
        this.loading = true;
        let sn = this.relaySN;

        const user = this._userService.getLocalUser();
        const isOwnRelay: boolean = this.checkIfOwnRelay(sn, user);

        if (isOwnRelay) {
          // redirect to "/relay/SN" from "public/relay/SN"
          this.redirectToOwnRelay(sn);
        }

        this._relayService.get(sn)
          .subscribe((success) => {
            this.setRelay(success["relay"]);

            // do not show Appliance button to relay KIA, KMA, KCP, TOP, KMT_101_40
            this.showApplianceButton = !(success["relay"].typeRelay || '').match(/^KIA|KMA|KCP|TOP|TOT_104_TH3|KMT_101_40|TOC_326_T5|TOT_106_TH6|TEP_321_T01/i);
            // end

            this.loading = false;
            this.checkConnectedStatus();
            this.getNowReadings();
            this.listenDisconnectRelay();

            let self = this;
            this.socket.emit('join room', {relayId: this.relayId, token: getToken()});
            this.socket.emit('join room monitoring', {relayId: this.relayId, token: getToken()});

            this.socket.on('need action response', (data) => {

                if (data.result && this.lastAction) {
                  this.notifyService.showMessage({
                    text: 'Relay turn on after delay to turn on',
                    type: "success"
                  });

                  this.mode_auto = false;
                  let settingsTimeToTurnOn = (this.relay && this.relay.settings && this.relay.settings.timeToOn) || 30;

                  setTimeout(() => {
                    this.disableAction = false;
                    this.sendAction = false;
                  }, settingsTimeToTurnOn * 1000);
                } else {
                  this.notifyService.showMessage({
                    text: 'Relay turned off',
                    type: "success"
                  });
                  this.disableAction = false;
                  this.sendAction = false;
                }
              }
            );

            this.subscriptions.push(this.socket.fromEvent('server:schedule:get_mode')
              .subscribe(
                (data) => {
                  if (this.relay.typeRelay === TEP_321_T01) {
                    try {
                      this.mode_auto = !!(+(parseInt(data['mode_auto']).toString(2).split('').reverse()[0]));
                    }catch(e) {
                      console.error(e);
                    }

                  }else {
                    this.mode_auto = data['mode_auto'];
                  }
                }
              ));

            this.subscriptions.push(this.socket.fromEvent('server:schedule:set_mode_auto')
              .subscribe(
                (data) => {
                  console.log('server:schedule:set_mode_auto', data);

                  if (this.relay.typeRelay === TEP_321_T01) {
                    try {
                      this.mode_auto = !!(+(parseInt(data['mode_auto']).toString(2).split('').reverse()[0]));
                    }catch(e) {
                      console.error(e);
                    }

                  }else {
                    this.mode_auto = !!Number(+data['mode_auto']);
                  }
                }
              ));

            let interval = setInterval(() => {
              self.checkScheduleMode();
              self.checkConnectedStatus();
            }, 15000);

            this.intervals.push(interval);
          });
      });



    let user = this._userService.getLocalUser();
    if (user && user['_id']) {
      this.user = user;
      this.relays = user['relays'];
    } else {
      let user = JSON.parse(localStorage.getItem('currentUser'));
      const userId = user && user._id;
      if (userId) {
        this._userService.getMe()
          .subscribe((user) => {
            this.user = user;
            this.relays = user['relays'];
          }, (e) => {
            console.log(e);
          })
      }
    }



    this.subscriptions.push(this.socket.fromEvent('server:need:action:response')
      .subscribe(
        (data) => {
          this.torRelaysState = data['cond_relay'];

          if (this.torRelaysStateLoading) {
            this.torRelaysStateLoading = false;
            if (this.torActionTimeout) {
              clearTimeout(this.torActionTimeout);
              this.torActionTimeout = undefined;
            }
          }
        }
      ));
  }

  ngOnDestroy() {
    console.log("DESTROY");

    this.socket.emit('leave room', {relayId: this.relayId, token: getToken()});

    this.subscriptions.forEach((item) => {
      item.unsubscribe();
    })

    this.intervals.forEach((item) => {
      clearInterval(item);
    })
  }

  setRelay(relay: any) {
    this.relay = relay;
    const { relayNames: {enable, list} } = relay;
    this.syncCounter = typeof this.relay['syncCounter'] === 'object'? this.relay['syncCounter']: this.syncCounter;

    this.relayName = this.relay.name;
    this.relayId = this.relay._id;
    this.relayState = this.relay.state;
    this.lastRelayConnection = +new Date(this.relay['lastConnection']);
    if (RELAYS_3_NAMED.includes(this.relay.typeRelay)) {
      this.torRelaysState = [0, 0, 0];
      this.torRelayNames = enable? list.map(item => item.name): ['реле 1', 'реле 2', 'реле 3'];
    }
    if (RELAYS_4_NAMED.includes(this.relay.typeRelay)) {
      this.torRelaysState = [0, 0, 0, 0];
      this.torRelayNames = enable? list.map(item => item.name): ['реле 1', 'реле 2', 'реле 3', 'реле 4'];
    }
    if (RELAYS_6_NAMED.includes(this.relay.typeRelay)) {
      this.torRelaysState = [0, 0, 0, 0, 0, 0];
      this.torRelayNames = enable? list.map(item => item.name): ['реле 1', 'реле 2', 'реле 3', 'реле 4', 'реле 5', 'реле 6'];
    }

    this.syncCounter = getSyncCounterData(
      this.syncCounter,
      this.relay.tot_posEnergyPA,
      this.relay.energySettings.voltageKoef || 1,
      this.relay.energySettings.currentKoef || 1
    );

    this.energyActive = this.syncCounter.value;
    this.energyActiveFloor = Math.floor(this.syncCounter.value)
  }

  checkScheduleMode() {
    this.socket.emit('user:schedule:get_mode',
      {relayId: this.relayId, token: getToken()},
      (data) => {
      });
  }

  chooseRelay() {
    let subpage
    let match = window.location.href.match(/relay\/\w*\/(\w+)/);
    subpage = match ? match[1].toString() : 'energy';
    this.router.navigate([`/relay/${this.relaySN}/${subpage}`]);
  }

  onResize(event) {
    this.isMobile = event.target.innerWidth < 1000;
    this.openedMenu = !this.isMobile;
  }

  checkConnectedStatus() {
    if (+new Date() - this.lastRelayConnection > 30000) {
      this.isRelayConnected = false;
      this.relay.status = 'disconnected';
      this.wifi_tooltip = OFFLINE_WIFI_TOOLTIP;
      this.wifi_level = 0;
    } else {
      this.isRelayConnected = true;
      this.relay.status = 'connected';
    }
  }

  getRelay(): void {
    this._relayService.get(this.relaySN)
      .subscribe(
        (success) => {
          console.log('get Relay', success);

          this.relay = success['relay'];
          this.user = success['user'];
        }
      )
  }

  getNowReadings(): void {
    let subscription = this.socket.fromEvent('send monitoring data')
      .subscribe(
        (data) => {
          // console.log('send monitoring data', data);
          if (data && data['relayId'] !== this.relayId) {
            console.log('wrong relay id');
            return
          }

          this.isRelayConnected = true;
          this.lastRelayConnection = +new Date();
          this.relay.status = 'connected';
          this.relay.lastConnection = new Date();
          this.ssidName = data['ssid'] || '';

          if (data["state"] !== undefined && !this.sendAction &&!this.openedActionModal) {
            this.relayState = data["state"] || false;
          } else if (data["state"] === this.relayState && this.sendAction) {
            this.relayState = data["state"];
            this.sendAction = false;
            this.disableAction = false;
          }

          // this for relay TOR-326-100
          if (data["state_tor326"] !== undefined && !this.openedActionModal) {
            if ((this.torRelaysState.join() === data["state_tor326"].join()) && this.torRelaysStateLoading) {
              this.torRelaysState = data["state_tor326"];
              this.torRelaysStateLoading = false;
              if (this.torActionTimeout) {
                clearTimeout(this.torActionTimeout)
                this.torActionTimeout = undefined;
              }
            }else if (!this.torRelaysStateLoading) {
              this.torRelaysState = data["state_tor326"];
            }
          }

          if (data["wifi_level"] !== undefined) {
            this.wifi_tooltip = this.ssidName? `${this.ssidName}: `: 'Рівень сигналу приладу: ';
            this.wifi_level = +data["wifi_level"];
            this.wifi_tooltip += `${this.wifi_level}dB`;
          }else {
            this.wifi_tooltip = OFFLINE_WIFI_TOOLTIP;
          }

          // Проверяем, если не послан запрос на откл-вкл, то разблокируем переключатель откл-вкл
          if (!this.sendAction && !this.openedActionModal) {
            this.disableAction = false;
          }

        }
      );

    this.subscriptions.push(subscription);
  }

  listenDisconnectRelay(): void {
    let subscription = this.socket.fromEvent('relay disconnected')
      .subscribe(
        (data) => {
          console.log('listenDisconnectRelay relay CMP', data);
          this.sendAction = false;
          this.disableAction = false;
          this.relay.status = 'disconnected';
        }
      );

    this.subscriptions.push(subscription);
  }

  makeAction(on: boolean): void {
    if (this.disableAction || !this.isRelayConnected) {
      return
    }
    this.relayState = on;
    this.lastAction = this.relayState;

    this.disableAction = true;
    this.sendAction = true;
    this.openedActionModal = false;

    if (this.relay.typeRelay === TEP_321_T01) {
      this.socket.emit('user:need:action',
        {
          relayId: this.relayId,
          token: getToken(),
          message: {
            cond_relay: on,
            relay: 0
          }
        },
        (response) => {
          this.notifyService.showMessage({
            text: response.message,
            type: response.success ? "success" : "error"
          });
        })
      return;
    }

    this.socket.emit('need action',
      {
        on: on,
        relayId: this.relayId,
        userId: this.user._id,
        username: this.user.username,
        token: getToken()
      },
      (response) => {
        console.log();

        this.notifyService.showMessage({
          text: response.message,
          type: response.success ? "success" : "error"
        });
      });
  }

  makeActionDialog(relayState) {
    if (this.disableAction || !this.isRelayConnected) {
      return
    }

    this.openedActionModal = true;
    let dialogRef = this._dialog.open(makeActionDialog, {
      width: '250px',
      data: {relayState: relayState}
    });

    dialogRef.afterClosed().subscribe((result) => {
      this.openedActionModal = false;
      if (!result || result.makeAction !== true) {
        this.relayState = !relayState;
        return;
      }

      if (result.makeAction === true) {
        this.makeAction(relayState);
      }
    })
  }

  changeRelayNameDialog(id: string) {
    let name
    this.relays.find((item) => {
      if (item._id === id) {
        name = item.name;
        return true
      } else {
        return false
      }
    });

    let dialogRef = this._dialog.open(ChangeRelayNameDialog, {
      width: '250px',
      data: {name: name, id: id}
    });

    dialogRef.afterClosed().subscribe((result) => {
      console.log('result', result);
      if (!result) return;

      if (!result.relayName || !result.id) return;

      this.changeName(result.id, result.relayName);
    })
  }

  askDeleteRelay(id: string) {
    let dialogRef = this._dialog.open(RemoveRelayDialog, {
      width: '250px',
      data: {id: id},
    });

    dialogRef.afterClosed().subscribe((result) => {
      console.log('result', result);
      if (!result) return;

      if (result.remove === true && result.id !== undefined) {
        this._userService.deleteRelay(this.user._id, result.id)
          .subscribe(
            (success) => {
              this.notifyService.showMessage(
                {
                  text: 'Прилад видалений',
                  type: 'success'
                }
              );

              this.router.navigate(['/user']);
            }, (error) => {
              console.log('error - ', error);
              this.notifyService.showMessage({
                type: 'error',
                text: 'Relay not removed'
              })
            }
          );
      }
    })
  }

  changeName(id: string, name: string) {
    this._userService.changeName(this.user._id, id, name)
      .subscribe(
        (success) => {
          let relay = success;
          this.notifyService.showMessage(
            {
              text: 'Name was changed',
              type: 'success'
            }
          );

          for (let i = 0; i < this.relays.length; i++) {
            if (this.relays[i]._id === id) {
              this.relays[i].name = relay['name'];
              break;
            }
          }

          this._userService.saveLocalUser(this.user);
        },
        (error) => {
          console.log('error - ', error);
          this.notifyService.showMessage({
            type: 'error',
            text: 'Relay not changed'
          })
        }
      )
  }

  makeActionTor(state, index){
    this.torRelaysStateLoading = true;
    let timeoutId;
    try {
      // in case something went wrong
      timeoutId = setTimeout(() => {
        this.notifyService.showMessage({
          type: 'success',
          text: 'Чекаємо підтвердження від реле'
        })
      }, 10000);

      if (this.torActionTimeout) {
        clearTimeout(this.torActionTimeout)
        this.torActionTimeout = undefined;
      }
      this.torActionTimeout = setTimeout(() => {
        this.torRelaysStateLoading = false;
      }, 30000);

      this.socket.emit('user:need:action',
        {
          relayId: this.relayId,
          token: getToken(),
          message: {
            cond_relay: state,
            relay: index
          }
        },
        (response) => {
          clearTimeout(timeoutId);
          if(!response.success) {
            this.torRelaysStateLoading = false;
            this.notifyService.showMessage({
              type: 'error',
              text: `Помилка ${state?'увімкнення':'вимкнення'} реле`
            })
            this.torRelaysState[index] = +(!this.torRelaysState[index]);
            return
          }

          this.notifyService.showMessage({
            type: 'success',
            text: 'Чекаємо підтвердження від реле'
          })
        })
    }catch(e){
      console.error(e);
      clearTimeout(timeoutId);
      this.torRelaysStateLoading = false;
      this.notifyService.showMessage({
        type: 'error',
        text: `Помилка ${this.torRelaysState[index]?'увімкнення':'вимкнення'} реле`
      })
    }
  }

  changeRelayTor(relayIndex) {
    if (this.torRelaysStateLoading || !this.isRelayConnected) {
      return
    }
    this.torRelaysStateLoading = true;
    this.openedActionModal = true;
    this.torRelaysState[relayIndex] = +(!this.torRelaysState[relayIndex]);
    let dialogRef = this._dialog.open(makeActionDialog, {
      width: '250px',
      data: {relayState: this.torRelaysState[relayIndex], relayName: this.torRelayNames[relayIndex]}
    });

    dialogRef.afterClosed().subscribe((result) => {
      this.openedActionModal = false;
      if (!result || result.makeAction !== true) {
        this.torRelaysState[relayIndex] = +(!this.torRelaysState[relayIndex]);
        this.torRelaysStateLoading = false;
        return;
      }

      if (result.makeAction === true) {
        this.makeActionTor(this.torRelaysState[relayIndex], relayIndex);
      }
    })
  }

  checkIfOwnRelay(serialNumber: string, user: any): boolean {
    const relays = user && user.relays || [];
    return relays.some((r) => {
      return r.serialNumber === serialNumber;
    })
  }

  redirectToOwnRelay(serialNumber: string) {
    let subpage
    let match = window.location.href.match(/relay\/\w*\/(\w+)/);
    const isPublicRoute = window.location.href.match(/public\/relay\//);
    if (isPublicRoute) {
      subpage = match ? match[1].toString() : 'energy';
      this.router.navigate([`/relay/${serialNumber}/${subpage}`]);
    }
  }
}
